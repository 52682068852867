import React, {useState, useEffect, useCallback} from "react";
import Img from "gatsby-image";
import { Link } from "gatsby";
import AdvancedSettings from "../settings/advanced_settings.json";
import StarsRatingComponent from './StarsRatingsComponent'
import { useEmblaCarousel } from "embla-carousel/react";
import { Transition } from '@headlessui/react'

function Carousel({ items,applyPosPaddingBottom, headerTransparent }) {
  const [viewportRef, embla] = useEmblaCarousel();
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);
  const onSelect = useCallback(() => {
    if (!embla) return;
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla]);

  useEffect(() => {
    if (!embla) return;
    embla.on("select", onSelect);
    onSelect();
  }, [embla, onSelect]);


  const NextArrow = (props) => {
    const { onClick } = props;

    return (
      <button
        className="carousel-icons flex items-center icon-container carousel-icon-left focus:outline-none focus:opacity-100"
        onClick={onClick}
      >
          <svg
            className="w-8 h-8"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15 19l-7-7 7-7"
            />
          </svg> 
      </button>
    );
  };

  const PrevArrow = (props) => {
    const { onClick } = props;
    return (
      <button
        className="carousel-icons flex items-center icon-container carousel-icon-right focus:outline-none focus:opacity-100"
        onClick={onClick}
      > 
          <svg
            className="w-8 h-8"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            />
          </svg> 
      </button>
    );
  };
 
  return (
    <section >
         <div className="embla">
              <div className="embla__viewport" ref={viewportRef}>
                <div className="embla__container">
                {items.map((slide, s) => {
          if(slide) {
            return (
              <div className="embla__slide" key={s}>
              <div key={s} className="h-auto bg-cover relative bg-black">
 
                              <Img
                                fluid={slide && slide.image.childImageSharp.fluid}
                                alt={slide && slide.title}
                                className="carousel-image absolute top-0 h-full"
                                style={{'opacity':'1', 'transform':'none'}}
                              /> 
           
                <div className={`${applyPosPaddingBottom ? 'pb-40' : ''} container py-32 carousel-content-container relative mx-auto px-4 md:px-6`} style={{paddingTop: headerTransparent ? '162px' : '128px'}}>
                  <div className="flex">
                    <div className="w-full md:w-2/3">
                      <h2 className="font-bold text-4xl md:text-5xl text-white font-display" style={{textShadow: 'rgba(0, 0, 0, 0.5) 0px 3px 10px'}}>{slide.title}</h2>
                      <span className="text-lg md:text-xl text-white" style={{textShadow: 'rgba(0, 0, 0, 0.5) 0px 3px 10px'}}>
                        {slide.description}
                      </span>
                      <div className="mt-4">
                      <Link to={`${slide.buttonlink}`} className="button-active-bhvr inline-block cursor-pointer transition duration-150 ease-in-out bg-brand-600 focus:bg-brand-700 hover:bg-brand-700 focus:outline-none font-semibold px-6 py-4 rounded-full text-white font-display">{slide.buttontext}</Link>
                   
                      </div>
                   
                        <StarsRatingComponent className={'mt-4'} reviewavg={AdvancedSettings.reviewavg} reviewscount={AdvancedSettings.reviewscount} enabled={AdvancedSettings.ratingstarsdisplayenabled} />
                      
                       </div>
                  </div>
                </div>
              </div>
              </div>
            );
          } else {
            return null
          }
         
        })}
                </div>
              </div>
        
            </div> 


     
    </section>
  );
}

export default Carousel;
