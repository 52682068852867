import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Carousel from '../components/carousel'
import FeaturedBlocks from '../components/FeaturedBlocks'
import MainContentBlockController from '../components/MainContentBlockController'
import SEO from '../components/SEO'

export const IndexPageTemplate = ({
  image,
  title,
  carousel,
  featuredservices,
  maincontentblocks, 
  heading,
  subheading, 
  description,
  intro,
  metatitle,
  metadescription,
  metakeywords,
  headerTransparent
}) => {  
  const headerOffsetTransparent = 95;
 
  return  (
    <>
     <SEO
        title={metatitle}
        description={metadescription} 
        keywords={metakeywords.join(', ')}
      />
      <div  style={{
        marginTop: `${
          headerTransparent ? `${headerOffsetTransparent * -1}px` : "0"
        }`,
      }}>
 
      <Carousel items={carousel} applyPosPaddingBottom={true} headerTransparent={headerTransparent}/>
      <FeaturedBlocks data={featuredservices} applyNegMarginTop={true}/>
      {maincontentblocks ? maincontentblocks.map((contentblock, i)=> (
          <MainContentBlockController key={i} contentblockID={contentblock.type} data={contentblock}/>
      )) : null}
     </div>
 
      {/* <section className="section section--gradient">
        <div className="container">
          <div className="section">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="content">
                  
                  
                 
                  <div className="columns">
                    <div className="column is-12 has-text-centered">
                      <Link className="btn" to="/products">
                        See all products
                      </Link>
                    </div>
                  </div>
                  <div className="column is-12">
                    <h3 className="has-text-weight-semibold is-size-2">
                      Latest stories
                    </h3>
                    <BlogRoll />
                    <div className="column is-12 has-text-centered">
                      <Link className="btn" to="/blog">
                        Read more
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  )
}

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string, 
  carousel:  PropTypes.array,
  featuredservices:  PropTypes.array,
  maincontentblocks:  PropTypes.array, 
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout headerTransparent={false} isHomePage={true}>
      <IndexPageTemplate
        metatitle={frontmatter.metatitle}
        metadescription={frontmatter.metadescription}
        metakeywords={frontmatter.metakeywords}
        image={frontmatter.image}
        title={frontmatter.title}
        carousel={frontmatter.carousel}
        featuredservices={frontmatter.featuredservices}
        maincontentblocks={frontmatter.maincontentblocks} 
        headerTransparent={false}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        metatitle
        metadescription
        metakeywords
        carousel {
          title 
          image {
            childImageSharp {
              fluid(maxWidth: 1366, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          buttontext
          description
          buttonlink
        }
        featuredservices {
          title
          description
          buttontext 
          buttonlink
          image { 
            childImageSharp {
              fluid(maxWidth: 300, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              } 
            }
          }
        }
        maincontentblocks {
          type
          label
          title 
          image {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              } 
            }
          }
          value
          description
          footerlogoshowcase {
            frontmatter {
              title
              images {
                imgtitle
                image {
                  publicURL
                  extension
                  childImageSharp {
                    fluid(maxHeight: 250, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          contentwithsidebarsidebar {
            sidebar {
              frontmatter { 
                title
                bodycontent 
                dynamicsidebarid
                renderashtml
                outercontainercss
                id
              }
            }
            sidebarcollection {
              frontmatter {
                title
                sidebars {
                  sidebar {
                    frontmatter {
                      title
                bodycontent 
                dynamicsidebarid
                renderashtml
                outercontainercss
                id
                    }
                  }
                }
              }
            }
          }
          bodycontent
          photogallery {
            frontmatter {
              title
              description
              images {
                imgtitle
                image {
                  childImageSharp {
                    fluid(maxWidth: 1300, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                    fixed(width: 135, height: 118, quality: 100) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
